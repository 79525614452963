import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OpentokService } from 'src/app/_services/opentok.service';
import { SettingsProvider } from "../../../_services/settingsProvider.service";
declare var UAParser: any;

@Component({
  selector: 'bxl-setup-checklist',
  templateUrl: 'setup-checklist.component.html',
})
export class SetupChecklistComponent implements OnInit {
  initialized = false;
  testingCamera = false;
  testingScreenShare = false;
  publisher: OT.Publisher;
  isOsx:boolean;

  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  constructor(private opentokService: OpentokService, public settings:SettingsProvider) {}

  ngOnInit(): void {
    document.body.style.backgroundColor = '#fafafa';
    this.initialized = true;

    let parser = new UAParser();
    // by default it takes ua string from current browser's window.navigator.userAgent

    let result = parser.getResult();
    console.log(result.os.name);

    this.isOsx = result.os.name === 'Mac OS';

  }


  testCamera() {
    this.testingCamera = true;
  }

  doneCamera() {
    this.testingCamera = false;
  }

  testScreenShare() {
    const OT = this.opentokService.getOT();
    this.testingScreenShare = true;
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, { videoSource: 'screen', insertMode: 'append', width: 500, height:400, name: 'chris' });
  }

  doneScreenShare() {
    this.testingScreenShare = false;
    this.publisher.destroy();
  }
}
