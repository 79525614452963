import { Component, OnInit, Input } from '@angular/core';
import { CourseViewModel, CourseEventStatus, CourseViewModelSimple } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Enums } from 'src/app/_models/generatedEnums';
import { Router } from "@angular/router";
import { NavigationService } from "../../_services/navigation-service";

@Component({
  selector: 'bxl-course-item',
  styles: [':host { display: contents; }'],
  templateUrl: 'course-item.component.html'
})
export class CourseItemComponent implements OnInit {
  initialized = false;
  @Input() course: CourseViewModelSimple;
  @Input() displayPrice = true;
  @Input() isGrid = false;
  @Input() rootRoute: string = "/";

  CourseEventStatus = CourseEventStatus;
  CourseEventStatusEnum = Enums.CourseEventStatusEnum;
  Math = Math;

  constructor(public auth: AuthenticationService, private router: Router, private navService: NavigationService) {}

  ngOnInit(): void {
    this.initialized = true;
  }

  public subscribe() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/account/subscription']);
    } else {
      this.navService.showLogin().then(() => this.router.navigate(['/account/subscription']));
    }
  }
}
