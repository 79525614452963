<!-- Property Item -->
<ng-container *ngIf="!isGrid">
  <div class="bg-white border-bottom pb-5 mb-5 pl-2 pr-2 pt-2 card">
    <div class="row">
      <div class="col-md-4 d-md-flex align-items-md-start flex-md-column mb-5 mb-md-0">
        <!-- Gallery -->
        <img class="img-fluid w-100" src="https://bxlimages.blob.core.windows.net/splash/{{ course.marketingSplashPath }}" [fallback]="'https://bxlimages.blob.core.windows.net/splash/noimage.png'"/>
      </div>

      <div class="col-md-8">
        <div class="row">
          <div class="col-9">
            <h1 class="h5 mb-1">
              <a [routerLink]="[rootRoute, 'courses', course.slug]">{{ course.title }}</a>
            </h1>
            <div class="row mb-1">
              <div class="col">
                <ul class="list-inline font-size-1 text-muted mb-1">
                  <li *ngIf="course.conferenceName" class="mb-1">{{ course.conferenceName }} Conference</li>
                  <li class="list-inline-item">
                    <a class="font-size-1"  [routerLink]="['/instructors', course.instructor.slug]">
                      <img class="img-fluid rounded-circle" style="max-width: 30px;" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ course.instructor.id }}-avatar.png"/>
                      {{ course.instructor.firstName + ' ' + course.instructor.lastName }}
                      <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
                    </a>
                    <a *ngFor="let instructor of course.coInstructors"  class="font-size-1 ml-2" [routerLink]="['/instructors', instructor.slug]">
                      <img class="img-fluid rounded-circle" style="max-width: 30px;" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png"/>
                      {{ instructor.firstName + ' ' + instructor.lastName }}
                      <span *ngIf="instructor.pronouns"> ({{ instructor.pronouns }})</span>
                    </a>
                  </li>
<!--                  <li *ngIf="course.includedInSubscription  && !auth.user?.hideSubscription" title="1 bedroom" class="mt-1" style="font-weight: 500">-->
<!--                    <span class="fas fa-lightbulb-dollar text-success mr-2"></span> Included with Subscription-->
<!--                    <a *ngIf="!auth.user?.hasSubscription" [routerLink]="['/account/subscription']">(Subscribe Now!)</a>-->
<!--                  </li>-->

                  <li *ngIf="course.includedInSubscription  && !auth.user?.hideSubscription && !course.userHasPurchased" title="1 bedroom" class="mt-1" style="font-weight: 500"><span class="fas fa-lightbulb-dollar text-success mr-2"></span> Included with a Subscription <a href="javascript:void(0)" *ngIf="!auth.user?.hasSubscription" (click)="subscribe()" >(Subscribe for $24.95)</a> </li>


                </ul>
              </div>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="position-relative d-inline-block mr-2 star-rating">
              <button *ngIf="course.reviews > 0" type="button" class="btn btn-xs btn-soft-warning btn-pill" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ course.rating | number: '1.2-2' }}
                <span class="fas fa-star"></span>
              </button>
              <button *ngIf="course.reviews == 0" type="button" [disabled]="true" class="btn btn-xs btn-soft-warning btn-pill">No Reviews</button>

              <div *ngIf="course.reviews > 0" id="rating1Dropdown" class="dropdown-menu dropdown-menu-right p-3" style="width: 190px; animation-duration: 300ms; right: 0px">
                <div class="d-flex align-items-center mb-2">
                  <span class="text-warning mr-2">{{ course.rating | number: '1.2-2' }}</span>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item text-warning">
                      <span *ngIf="course.rating > 1" class="fas fa-star"></span>
                      <span *ngIf="course.rating > 2" class="fas fa-star"></span>
                      <span *ngIf="course.rating > 3" class="fas fa-star"></span>
                      <span *ngIf="course.rating > 4" class="fas fa-star"></span>
                      <span *ngIf="course.rating == 1" class="fas fa-star"></span>
                      <span *ngIf="course.rating == 2" class="fas fa-star"></span>
                      <span *ngIf="course.rating == 3" class="fas fa-star"></span>
                      <span *ngIf="course.rating == 4" class="fas fa-star"></span>
                      <span *ngIf="course.rating == 5" class="fas fa-star"></span>
                      <span *ngIf="course.rating % 1 < 0.9 && course.rating % 1 > 0.3" class="fas fa-star-half-alt"></span>
                      <span *ngIf="course.rating % 1 >= 0.9 && course.rating % 1 <= 0.3" class="fas fa-star"></span>
                      <span *ngIf="5 - Math.ceil(course.rating) > 0" class="far fa-star"></span>
                      <span *ngIf="5 - Math.ceil(course.rating) > 1" class="far fa-star"></span>
                      <span *ngIf="5 - Math.ceil(course.rating) > 2" class="far fa-star"></span>
                      <span *ngIf="5 - Math.ceil(course.rating) > 3" class="far fa-star"></span>
                      <span *ngIf="5 - Math.ceil(course.rating) > 4" class="far fa-star"></span>
                    </li>
                  </ul>
                </div>

                <p class="text-dark mb-0">Overal Rating</p>
                <p class="mb-0">Based on {{ course.reviews }} reviews</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Location -->

        <!-- End Location -->

        <!-- Icon Blocks -->
        <bxl-ceu-display [CEUs]="course.ceus"></bxl-ceu-display>
        <!-- End Icon Blocks -->

        <p class="font-size-1">{{ course.description | stripHtml | truncate: 400 }}</p>

        <!-- Contacts -->
        <div class="d-flex align-items-center font-size-1">
          <a class="text-secondary mr-4" href="javascript:;">
            <span class="fas fa-clock mr-1"></span>
            {{ course.duration | duration }}
          </a>
          <!-- <a class="text-secondary mr-4" href="javascript:;">
          <span class="fas fa-star mr-1"></span>
          Save
        </a> -->
          <ng-container *ngIf="displayPrice && !course.userHasPurchased">
            <a *ngIf="course.price > 0" class="btn btn-sm btn-soft-alternate transition-3d-hover ml-auto" [routerLink]="['/courses', course.slug]">
              <span *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !course.released">(Coming Soon)</span> {{ course.price | currency: course.currencyCode:'symbol' }}
            </a>
            <a *ngIf="course.price == 0" class="btn btn-sm btn-soft-success transition-3d-hover ml-auto" [routerLink]="['/courses', course.slug]">
              <span *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !course.released">(Coming Soon)</span> Watch for Free!
            </a>
          </ng-container>

          <ng-container *ngIf="displayPrice && course.userHasPurchased">
            <!-- <button *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.ReleaseWithConference] || course.released" class="btn btn-sm btn-soft-success ml-auto" [disabled]="true" [routerLink]="['/courses', course.slug]">
              <i class="fas fa-check"></i>
              In Library
            </button> -->
            <button *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !course.released" class="btn btn-sm btn-warning ml-auto" [disabled]="true" [routerLink]="['/courses', course.slug]">
              <i class="fas fa-check"></i>
              Preordered
            </button>

          </ng-container>

          <button *ngIf="course.userHasPurchased" type="button" class="btn btn-sm btn-soft-success transition-3d-hover ml-auto" [routerLink]="['/courses', course.slug]">
            <i class="fas fa-eye"></i>
            {{ 'Watch Course' | translate }}
          </button>

        </div>
        <!-- End Contacts -->
      </div>
    </div>
  </div>
  <!-- End Property Item -->
</ng-container>

<!--
------------------------------------------
------------------------------------------
-------------------------------------------------
-------------------------------------------------
--------------------------------------------------------
------------------------------------------
-------------------------------------------------


-->

<ng-container *ngIf="isGrid">
  <img class="card-img-top w-100" src="https://bxlimages.blob.core.windows.net/splash/{{ course.marketingSplashPath }}" [fallback]="'https://bxlimages.blob.core.windows.net/splash/noimage.png'"/>

  <div class="card-body pl-2 pr-2 pb-4 pt-1">
    <div class="row no-gutters">
      <div class="col-md-6">
        <div class="d-flex justify-content-between font-size-1">
          <bxl-ceu-display [CEUs]="course.ceus"></bxl-ceu-display>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <ul class="list-inline font-size-1">
          <li class="list-inline-item text-right" style="font-weight: 500">
            <div *ngIf="course.price > 0">
              <span *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !course.released">(Coming Soon)</span> {{ course.price | currency: course.currencyCode:'symbol' }}
            </div>
            <div *ngIf="course.price == 0">
              <span *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.ReleaseWithConference] && !course.released">(Coming Soon)</span> Free!
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-1 course-title">
          <a class="text-dark" [routerLink]="['/courses', course.slug]">{{ course.title }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ul class="list-inline font-size-1 text-muted mb-0">
      <li class="list-inline-item">
        <a class="font-size-1" [routerLink]="['/instructors', course.instructor.slug]">
          <img class="carosel-img img-fluid rounded-circle" style="max-width: 30px;" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ course.instructor.id }}-avatar.png"/>
          {{ course.instructor.firstName + ' ' + course.instructor.lastName }}
        </a>
        <a *ngFor="let instructor of course.coInstructors" class="font-size-1 ml-2" [routerLink]="['/instructors', instructor.slug]">
          <img class="carosel-img img-fluid rounded-circle" style="max-width: 30px;" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png"/>
          {{ instructor.firstName + ' ' + instructor.lastName }}
        </a>
      </li>
    </ul>
  </div>

  <!-- End Property Item -->
</ng-container>
