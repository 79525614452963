import { Component, Input, OnInit, AfterViewInit, HostListener, ElementRef, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { UserProfileViewModel, TagViewModel, OrganizationViewModel, OrganizationRole } from '../../_models/generatedModels';
import { RegistrationModalComponent } from 'src/app/events/registration/registration-modal.component';
import { StaticDataService, UserService, OrganizationService } from 'src/app/_services/generatedServices';
import { NavigationService } from 'src/app/_services/navigation-service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Subscription } from 'rxjs';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent implements OnInit, AfterViewInit {
  @Input() isCompanyPage: boolean;
  isExpanded = false;
  fullWidthHeader = false;
  closeResult: string;
  tags: TagViewModel[];
  verificationEmailSent = false;
  queryRouteSubscription: Subscription;

  @ViewChild('navBar', { static: true }) navBar;
  @ViewChild('hamburger', { static: true }) hamburger;
  OrganizationRole = OrganizationRole;
  // public user: UserProfileViewModel;

  constructor(public translate: TranslateService, public settings: SettingsProvider, private route: ActivatedRoute, private ref: ChangeDetectorRef, private modalService: NgbModal, private renderer: Renderer2, private organization: OrganizationService, private element: ElementRef, private toaster: ToasterService, private user: UserService, public auth: AuthenticationService, private router: Router, public navService: NavigationService, private staticData: StaticDataService) {}

  @HostListener('click', ['$event']) click(event) {
    let dropdown = this.element.nativeElement.querySelectorAll('a');
    dropdown.forEach((element) => {
      if (element && element.contains(event.target)) {
        this.renderer.removeClass(this.navBar.nativeElement, 'show');
        this.renderer.addClass(this.hamburger.nativeElement, 'collapsed');
        this.renderer.setAttribute(this.hamburger.nativeElement, 'aria-expanded', 'false');
      }
    });
  }

  ngOnInit(): void {
    if (this.router.url.indexOf('account') >= 0 || this.router.url.indexOf('super-user') >= 0) {
      this.fullWidthHeader = true;
    } else {
      this.fullWidthHeader = false;
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (evt.url.indexOf('account') >= 0 || this.router.url.indexOf('super-user') >= 0) {
        this.fullWidthHeader = true;
      } else {
        this.fullWidthHeader = false;
      }
    });

    this.staticData.getNavTags().subscribe((tags) => {
      this.tags = tags;
    });

    this.fetchProfile();
  }

  get isConfirmEmailRoute(): boolean {
    return this.router.url.indexOf('confirmEmail') >= 0;
  }

  changeLanguage(code: string) {
    localStorage.setItem('language', code);
    this.translate.use(code);
  }

  fetchProfile() {
    if (this.auth.isLoggedIn()) {
      this.auth.fetchUserProfile().subscribe();
    }
  }

  resendEmailVerification() {
    this.user.sendEmailVerification().subscribe((result) => {
      this.verificationEmailSent = true;
      this.toaster.success('Email Verification Sent', 'Success');
    });
  }

  getStartedAuth() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/account/author-course']);
    } else {
      this.loginClicked();
    }
  }

  ngAfterViewInit() {
    this.queryRouteSubscription = this.route.queryParamMap.subscribe((queryParam) => {
      let query = queryParam.get('showLogin');

      if (query) {
        if (!this.auth.isLoggedIn()) {
          this.loginClicked();
        }
      }

      (<any>$)('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 767.98,
        hideTimeOut: 0,
      });
    });
  }

  open(content, size) {
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  loginClicked() {
    const modalRef = this.modalService.open(RegistrationModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    return;

    // const modalRef = this.modalService.open(LoginModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    // modalRef.result.then((result) => {
    //   this.fetchProfile();
    // }, (reason) => {

    // });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    this.auth.logout();
    //this.router.navigate(['/']);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  protected readonly Set = Set;
}
