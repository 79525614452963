<page-load-header *ngIf="!initialized"></page-load-header>
<!-- ========== MAIN CONTENT ========== -->
<main *ngIf="initialized" id="content" role="main" class="event-details-page">
  <!-- App Description Section -->
  <div *ngIf="notAuthorized" class="container space-2 text-center">
    <div class="alert alert-danger">
      <h4>This course is no longer available.</h4>
      <h5>If this is an internal course for your organization, make sure you are logged in and you have verified your email.</h5>
    </div>
  </div>
  <div *ngIf="!notAuthorized" class="container space-1">
    <!-- Header -->
    <div class="row align-items-md-center mb-5">
      <div class="col-md-5 mb-5 mb-md-0">
        <!-- Breadcrumb -->
        <nav class="d-inline-block rounded" aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-no-gutter font-size-1 mb-0">
            <li class="breadcrumb-item">
              <a [routerLink]="['../../','course-library']">Course Library</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">View Course</li>
          </ol>
        </nav>
        <!-- End Breadcrumb -->
      </div>

      <div class="col-md-7 text-md-right d-none d-lg-block">
        <!-- Form -->
        <form class="js-validate d-flex justify-content-md-end align-items-center">
          <label class="sr-only" for="signupSrEmail">Search courses</label>
          <div class="d-inline-block w-90 mr-2">
            <input type="email" class="form-control" name="email" id="signupSrEmail" [(ngModel)]="query" (keyup.enter)="search()" placeholder="Search for courses" aria-label="Search for courses"/>
          </div>
          <button type="button" (click)="search()" class="btn btn-primary">
            <span class="fas fa-search"></span>
          </button>
        </form>
        <!-- End Form -->
      </div>
    </div>
    <!-- End Header -->

    <!-- Content -->

    <!-- <bxl-course-viewer *ngIf="course.userHasPurchased && course.released" [course]="course"></bxl-course-viewer> -->


    <!-- End Content -->
  </div>
  <!-- End App Description Section -->

  <!-- End Related Items Section -->
</main>
<div *ngIf="initialized && course">
  <bxl-purchased-course *ngIf="course.userHasPurchased && course.released" [course]="course"></bxl-purchased-course>

  <bxl-unpurchased-course *ngIf="!course.userHasPurchased || !course.released" (coursePurchased)="coursePurchased($event)" [course]="course"></bxl-unpurchased-course>
</div>
<!-- ========== END MAIN CONTENT ========== -->
