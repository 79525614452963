import { Component, OnInit } from '@angular/core';
import { InstructorService, CourseService, EventService } from 'src/app/_services/generatedServices';
import { InstructorViewModel, CourseViewModel, EventSearchParameters, EventViewModel, ConferenceViewModel, CourseEventStatus, ConferenceViewModelSimple, CourseViewModelSimple } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { SettingsProvider } from '../../_services/settingsProvider.service';
import { ResizedEvent } from 'angular-resize-event';
import { Enums } from '../../_models/generatedEnums';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-home-gathergo',
  templateUrl: 'gathergo-home.component.html',
})
export class GathergoHomeComponent implements OnInit {
  instructors: InstructorViewModel[];
  featuredCourses: CourseViewModelSimple[];
  CourseEventStatus = CourseEventStatus;
  CourseEventStatusEnum = Enums.CourseEventStatusEnum;
  initialized: boolean;
  events: EventViewModel[];
  query: string;
  currentBeakpoint: string;
  conferences: ConferenceViewModelSimple[];
  responsiveOptions = [
    {
      breakpoint: '1590px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '992px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  responsiveOptions2 = [
    {
      breakpoint: '1590px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '992px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(public settings: SettingsProvider, private eventService: EventService, private router: Router, private instructorData: InstructorService, private courseData: CourseService, public auth: AuthenticationService) {}

  ngOnInit(): void {
    if (this.settings.applicationName == 'BehaviorLive') {
      this.initialized = true;

      forkJoin(this.eventService.getUpcomingConferencesCachable(true), this.courseData.getFeatured(10)).subscribe((results) => {
        this.conferences = results[0];
        this.featuredCourses = results[1];
      });
    } else {
      let params = new EventSearchParameters();
      params.onlyShowFuture = true;

      this.eventService.getAll(params).subscribe((events) => {
        this.events = events;
        this.initialized = true;
      });
    }
  }

  onResized(event: ResizedEvent) {
    //this.isMobile = event.newWidth < 576;

    if (event.newRect.width < 576) {
      this.currentBeakpoint = 'xs';
    }
    if (event.newRect.width >= 576) {
      this.currentBeakpoint = 'sm';
    }
    if (event.newRect.width >= 768) {
      this.currentBeakpoint = 'md';
    }
    if (event.newRect.width >= 992) {
      this.currentBeakpoint = 'lg';
    }
    if (event.newRect.width >= 1200) {
      this.currentBeakpoint = 'xl';
    }
    if (event.newRect.width >= 1590) {
      this.currentBeakpoint = 'xxl';
    }
  }

  get fillerCount(): any {
    let columns = 1;

    switch (this.currentBeakpoint) {
      case 'xxl':
        columns = 4;
        break;
      case 'xl':
        columns = 3;
        break;
      case 'lg':
        columns = 3;
        break;
      case 'md':
        columns = 2;
        break;
      case 'sm':
        columns = 2;
        break;
      case 'xs':
        columns = 1;
        break;
    }

    let len = this.conferences.length;
    let filler = columns - (len % columns);
    if (columns === filler) {
      return Array(0);
    }
    return Array(filler);
  }

  search() {
    this.router.navigate(['/course-library'], { queryParams: { q: this.query } });
  }
}
