<page-load-header *ngIf="!initialized"></page-load-header>

<!-- ========== MAIN CONTENT ========== -->
<main *ngIf="initialized" id="content" role="main">
  <!-- Search Section -->
  <div class="bg-blue">
    <div class="container space-1">
      <div class="row mx-gutters-2">
        <div class="col-md-12 mb-2 mb-sm-0">
          <h1 class="h4 text-white font-weight-medium">On-Demand Library ({{ filterData.totalCount }})</h1>
        </div>
        <div class="col-lg mb-3 mb-lg-0">
          <!-- Search Property Input -->
          <div class="js-focus-state">
            <label class="sr-only" for="searchPropertySr">Search Library</label>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" id="searchProperty">
                  <span class="fas fa-search"></span>
                </span>
              </div>
              <input type="text" class="form-control" (keyup.enter)="searchService.search(true)" name="text" [(ngModel)]="searchService.courseSearchParams.query" id="searchPropertySr" placeholder="subject, title, speaker" aria-label="Search property" aria-describedby="searchProperty"/>
            </div>
          </div>
          <!-- End Search Property Input -->
        </div>

        <div class="col-sm-auto">
          <button type="button" (click)="searchService.search(true)" class="btn btn-sm btn-primary">Search</button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Search Section -->

  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-12">
        <form class="js-validate ng-untouched ng-pristine ng-valid" novalidate="">
          <ul class="list-inline mb-0">
            <!---->
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'Organization'" [options]="filterData.organizations" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.organizationId" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'Conference'" [options]="filterData.conferences" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.conferenceId" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'CEU Type'" [options]="filterData.ceuTypes" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.certifyingBodyId" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'CEU Count'" [options]="filterData.ceuCount" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.ceuCount" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'Topic'" [options]="filterData.tags" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.tag" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'Type'" [options]="filterData.speakerTypes" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.speakerType" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
            <li class="list-inline-item mt-2">
              <filter-dropdown [title]="'Subscription'" [options]="filterData.subscriptions" [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchService.courseSearchParams.includedInSubscription" (ngModelChange)="searchService.search(true)"></filter-dropdown>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
  <!-- List of Properties Section -->
  <page-load-header *ngIf="searchService.loading"></page-load-header>
  <div *ngIf="!searchService.loading" class="container space-top-1 space-bottom-2  ad-container">
    <div class="site-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="row text-center" *ngIf="!searchService.resultCount">
            <div class="col bg-light pt-5 pb-5">
              <h2>No Results</h2></div>
          </div>
          <ng-container *ngFor="let course of searchService.courses; let idx=index;">
            <bxl-course-item [course]="course"></bxl-course-item>

            <div *ngIf="idx%5 == 0 && idx>0" class="mt-1 mb-5">
              <ad-display [isMargin]="false"></ad-display>
            </div>
          </ng-container>
        </div>
      </div>
      <nav *ngIf="searchService.resultCount > searchService.courses.length" aria-label="Page navigation">
        <button type="button" (click)="loadMore()" [promiseBtn]="loadingMore" class="btn btn-primary btn-block">Load More</button>
      </nav>
    </div>
    <div class="ad-content">
      <ad-display [isMargin]="true"></ad-display>
    </div>
  </div>
  <!-- End List of Properties Section -->
</main>
<!-- ========== END MAIN CONTENT ========== -->
