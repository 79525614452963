import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { SettingsProvider } from './settingsProvider.service';

@Injectable()
export class AppInsightsLoggerService {
  appInsights: ApplicationInsights;

  initAppInsights(settingsProvider: SettingsProvider) {
    if (!settingsProvider.applicationInsightsInstrumentationKey) {
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: settingsProvider.applicationInsightsInstrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  setUser(userId: number) {
    this.appInsights.setAuthenticatedUserContext(userId.toString());
  }

  logPageView(name?: string, url?: string) {
    this.appInsights?.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights?.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error) {
    this.appInsights?.trackException({ exception: exception, severityLevel: SeverityLevel.Critical });
  }

  logError(exception: Error) {
    this.appInsights?.trackException({ exception: exception, severityLevel: SeverityLevel.Error });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackTrace({ message: message }, properties);
  }
}
