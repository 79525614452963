<page-load-header *ngIf="!initialized"></page-load-header>
<main id="orgAdmin" role="main" *ngIf="initialized">

  <nav class="navbar navbar-expand navbar-dark" style="background-color: #2e3f50; padding-top: 0 !important; padding-bottom: 0 !important;">
    <a class="navbar-brand" href="{{settingsProvider.brand.url}}"><img src="{{settingsProvider.brand.appIcon}}" style="height: 40px;"></a>
    <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbarSupportedContent" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
<!--        <li class="nav-item"></li>-->
        <li class="nav-item tool p-0">
          <a class="nav-link" href="javascript:void(0)" (click)="increaseFont()">
            <i class="fas fa-sort-size-up-alt"></i>
          </a>
        </li>
        <li class="nav-item tool p-0">
          <a class="nav-link" href="javascript:void(0)" (click)="decreaseFont()">
            <i class="fas fa-sort-size-down-alt"></i>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav my-2 my-lg-0">
        <li class="nav-item tool" style="padding: 0 !important;">
          <select class="form-control-sm" [(ngModel)]="selectedRoom" (change)="roomUpdated()">
            <option *ngFor="let room of rooms" [value]="room.id">{{ room.roomName }}</option>
          </select>
        </li>
      </ul>
    </div>
  </nav>
  <div class="bg-light vh-100">
    <div *ngIf="initialized" class="container pt-5">
      <div class="text-center card">
        <div #card class="card-body" style="max-height: 200px; overflow: scroll" *ngIf="selectedRoom && strCaptionsDisplay && strCaptionsDisplay.length > 0">
          <h2 [style.font-size]="fontSize+ 'px'" *ngFor="let caption of strCaptionsDisplay">{{ caption }}</h2>
        </div>
        <div class="card-body" style="max-height: 200px; overflow: scroll" *ngIf="selectedRoom && (!strCaptionsDisplay || strCaptionsDisplay.length == 0)">
          <h2 [style.font-size]="fontSize+ 'px'">No Captions Yet</h2>
        </div>
        <div class="card-body" style="max-height: 200px; overflow: scroll" *ngIf="!selectedRoom">
          <h3>Please choose a room to start viewing captions!</h3>
          <select class="form-control"  (change)="roomUpdated()" style="max-width: 300px;margin-left: auto; margin-right: auto" [(ngModel)]="selectedRoom">
            <option *ngFor="let room of rooms" [value]="room.id">{{ room.roomName }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</main>
