<ng-container *ngIf="course">
  <main class="event-details-page">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
<!--          <h1  class="event-text" [ngClass]="{ 'event-detail-title-long': course.title.length > 75, 'event-detail-title': course.title.length <= 75 }">{{ course.title }}</h1>-->

          <h3 class="event-text">{{ course.title }}</h3>

          <p *ngIf="course.restrictToOrganizationName && !course.conferenceName" class="mb-3">
            <strong class="event-text">{{ 'presented-by' | translate }}: </strong>
            <a style="color:#00bff3" [routerLink]="['/organizations', course.restrictToOrganizationId]">{{ course.restrictToOrganizationName }}</a>
          </p>
          <div class="row m-0">
            <div class="mr-2 mb-2">
              <a class="font-size-1" style="color:#00bff3" [routerLink]="['../../instructors', course.instructor.slug]">
                <img class="img-fluid rounded-circle mr-1" style="width: 30px; height: 30px" style="max-width: 30px" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ course.instructor.id }}-avatar.png"/>
                {{ course.instructor.firstName + ' ' + course.instructor.lastName }}
                <span *ngIf="course.instructor.pronouns"> ({{ course.instructor.pronouns }})</span>
              </a>
            </div>
            <div *ngFor="let instructor of course.coInstructors" class="mr-2 mb-2">
              <a *ngIf="!instructor.hidden" style="color:#00bff3" class="font-size-1" [routerLink]="['../../instructors', instructor.slug]">
                <img class="img-fluid rounded-circle mr-1" style="width: 30px; height: 30px" style="max-width: 30px" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png"/>
                {{ instructor.firstName + ' ' + instructor.lastName }}
                <span *ngIf="instructor.pronouns"> ({{ instructor.pronouns }})</span>
              </a>
            </div>
          </div>


          <div class="mb-7">
            <h5 *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString() && course.includedInSubscription  && !auth.user?.hideSubscription && !course.userHasPurchased"  class="mt-1 text-white" style="font-weight: 500"><span class="fas fa-lightbulb-dollar text-success mr-2"></span> Included with a Subscription <a style="color:#e84d56" href="javascript:void(0)" *ngIf="!auth.user?.hasSubscription" (click)="subscribe()" >(Subscribe for $24.95)</a> </h5>

            <div class="row mb-1 text-white">

            </div>

            <button *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" type="button" class="btn btn-lg btn-block" (click)="purchase()" [course-purchase-button]="course"></button>
            <div class=" mb-2 mt-2 text-white">
              <div class="event-detail-label">Price</div>
              <div *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" >
                <h5 *ngIf="course.price > 0">
                  {{ course.price | currency: course.currencyCode:'symbol' }}
                </h5>
                <h5 *ngIf="course.price == 0">Free!</h5>
              </div>
              <div *ngIf="course.courseEventStatus.toString() == CourseEventStatus[CourseEventStatus.NotForSale].toString()" >
                <h5>Not for Sale</h5>
              </div>

            </div>

            <div class=" mb-2 text-white">
              <div class="event-detail-label">CEUs</div>
              <div *ngIf="course.assessmentId" class="d-block h5">{{ course.ceus | ceuDisplay }}</div>
              <div *ngIf="!course.assessmentId" class="d-block h6 align-middle text-danger">No CEUs (Yet) Missing Assessment From Speaker</div>

            </div>
            <div *ngIf="course.tags.length > 0" class=" mb-2 order-2 order-md-2">
              <div class="event-detail-label">Categories</div>
              <div class="d-block h5">
            <span *ngFor="let tag of course.tags" class="d-inline-block mr-1 mb-2"
            ><a class="btn btn-xs btn-soft-secondary" [routerLink]="['/course-library', tag]">{{ tag }}</a></span
            ></div>

            </div>
          </div>
<!--          <button *ngIf="course.courseEventStatus.toString() != CourseEventStatus[CourseEventStatus.NotForSale].toString()" type="button" class="btn btn-lg btn-block" (click)="purchase()" [course-purchase-button]="course"></button>-->

        </div>
        <div class="col-md-6">
          <div class="row event-text">
            <div class="col-md-12 mb-3 order-6 order-md-1">
              <img class="img-fluid event-image" src="https://bxlimages.blob.core.windows.net/images/{{ course.marketingSplashPath }}" [fallback]="'/fetchimage?fileName=c7164287-3135-4309-b3c8-4768092714e4Coming_soon.jpg'"/>
            </div>





            <!-- <div class="col-md-6 mb-2 order-5 order-md-2">
              <div class="event-detail-label">{{ 'Categories' | translate }}</div>
              <div class="d-block h5">
                <span *ngFor="let tag of event.tags" class="d-inline-block mr-1 mb-2"
                  ><a class="btn btn-xs text-primary mt-2" [ngClass]="{ 'btn-outline-primary': isConference, 'btn-white': !isConference }" [routerLink]="['/course-library', tag]">{{ tag }}</a></span
                >
              </div>
            </div> -->
          </div>
        </div>

      </div>
    </div>
  </main>
  <div class="container">

    <div class="row">
      <div class="col-md-12  mb-9 mb-md-0">
        <div class="ml-lg-2 mt-2">

          <!-- Features Section -->
          <div id="SVGmockupBg">
            <!-- Nav Classic -->
            <ul class="nav nav-classic nav-rounded nav-justified border" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link font-weight-medium active" id="pills-one-example1-tab" data-toggle="pill" href="#pills-one-example1" role="tab" aria-controls="pills-one-example1" aria-selected="true">
                  <div class="text-admin-primary d-md-flex justify-content-md-center align-items-md-center">
                    <i style="font-size: 1.5rem" class="fas fa-edit mr-"></i>
                    <span class="d-none d-md-block">Course</span>&nbsp;Info
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link font-weight-medium" id="pills-two-example1-tab" data-toggle="pill" href="#pills-two-example1" role="tab" aria-controls="pills-two-example1" aria-selected="false">
                  <div class="text-admin-primary d-md-flex justify-content-md-center align-items-md-center">
                    <i style="font-size: 1.5rem" class="fas fa-comments-alt mr-2"></i>
                    Reviews
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link font-weight-medium" id="pills-three-example1-tab" data-toggle="pill" href="#pills-three-example1" role="tab" aria-controls="pills-three-example1" aria-selected="false">
                  <div class="text-admin-primary d-md-flex justify-content-md-center align-items-md-center">
                    <i style="font-size: 1.5rem" class="fas fa-folder-download mr-2"></i>
                    Materials
                  </div>
                </a>
              </li>
            </ul>
            <!-- End Nav Classic -->

            <!-- Tab Content -->
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade pt-6 show active" id="pills-one-example1" role="tabpanel" aria-labelledby="pills-one-example1-tab">
                <video class="azuremediaplayer amp-default-skin amp-big-play-centered embed-responsive embed-responsive-16by9" controls *ngIf="course.trailerAssetId" poster="https://bxlimages.blob.core.windows.net/posters/{{ course.marketingSplashPath }}" id="vplayer"></video>
                <h3 class="mt-3">Course Description</h3>
                <div [editorHTML]="course.description"></div>
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="mt-3 ckembed">Learning Objectives</h4>
                    <ul>
                      <li *ngIf="course.learningObjective1">{{ course.learningObjective1 }}</li>
                      <li *ngIf="course.learningObjective2">{{ course.learningObjective2 }}</li>
                      <li *ngIf="course.learningObjective3">{{ course.learningObjective3 }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade pt-6" id="pills-two-example1" role="tabpanel" aria-labelledby="pills-two-example1-tab">
                <div *ngIf="reviews && reviews.length == 0" class="text-center">
                  <div class="mb-5">
                    <p class="font-size-3">No Reviews Yet</p>
                  </div>
                </div>
                <ng-container *ngIf="reviews && reviews.length > 0">
                  <!-- Review Header -->
                  <div class="d-sm-flex justify-content-sm-between align-items-sm-center border-bottom pb-3 mb-4">
                    <div class="d-flex align-items-center mb-2 mb-sm-0">
                      <h4 class="h5 font-weight-semi-bold mb-0">
                        Reviews
                        <small class="text-muted ml-1">({{ reviews.length }})</small>
                      </h4>
                    </div>

                    <!-- Select -->
                    <select class="js-select selectpicker dropdown-select" data-width="fit" data-style="btn-soft-secondary btn-xs">
                      <option value="showCategoryByFilterSelect1" selected>Newest first</option>
                      <option value="showCategoryByFilterSelect2">Highest rated</option>
                      <option value="showCategoryByFilterSelect3">Lowest rated</option>
                    </select>
                    <!-- End Select -->
                  </div>
                  <!-- End Review Header -->

                  <!-- Review -->

                  <div *ngFor="let review of reviews" class="media">
                    <div class="u-avatar mr-3">
                      <img class="img-fluid rounded-circle" src="https://bxlimages.blob.core.windows.net/avatar-cache/231-avatar.png" alt="Images Description"/>
                    </div>
                    <div class="media-body border-bottom pb-6 mb-6">
                      <span class="h6 font-weight-semi-bold mr-2">{{ review.authorFirstName.substr(0, 1).toUpperCase() }} {{ review.authorLastName.substr(0, 1).toUpperCase() }}</span>
                      <span class="text-warning">
                      <span *ngIf="review.rating > 0" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 1" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 2" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 3" class="fas fa-star"></span>
                      <span *ngIf="review.rating > 4" class="fas fa-star"></span>
                    </span>

                      <p>{{ review.text }}</p>

                      <!-- Reply -->

                      <!-- End Reply -->
                    </div>
                  </div>
                </ng-container>
                <!-- End Review -->

                <div *ngIf="false" class="text-center">
                  <a href="#">Read More Reviews
                    <span class="fas fa-angle-right ml-1"></span></a>
                </div>
              </div>

              <div class="tab-pane fade pt-6" id="pills-three-example1" role="tabpanel" aria-labelledby="pills-three-example1-tab">
                <h4 class="h5 font-weight-semi-bold mb-3">Materials</h4>

                <div *ngIf="!course.materials || course.materials.length == 0" class="text-center">
                  <div class="mb-5">
                    <p class="font-size-3">No Materials</p>
                  </div>
                </div>
                <div>
                  <table>
                    <tr *ngFor="let material of course.materials">
                      <td>
                        <h4>
                          <img style="width: 40px" src="../../assets/filetypes/{{ material.fileType }}.png"/>
                          {{ material.description }}
                          <span class="h6 text-muted">(Available with purchase)</span>
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- End Tab Content -->
          </div>
          <!-- End Features Section -->
        </div>
      </div>

    </div>
  </div>

</ng-container>
