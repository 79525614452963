<page-load-header *ngIf="!initialized"></page-load-header>
<div class="contain">
  <div *ngIf="initialized" class="contain-center" style="background-color: #fafafa;">
    <div class="row mt-5">
      <div class="col text-center">
        <svg width="52px" height="56px" viewBox="0 0 52 56">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(1.000000, 0.000000)">
              <path id="Fill-1" d="M5.25,27.422 C10.266,32.5 17.406,41.996 21.113,49.406 C28.727,32.848 38.66,17.457 50.609,3.699 L47.902,0.641 C39.848,8.613 27.316,23.727 20.82,35.891 C17.797,30.852 14.184,26.188 10.059,22 L5.25,27.422 Z" fill="#26C465"></path>
              <path id="Stroke-2" d="M43.695,24.871 L43.695,53.273 C43.695,53.965 43.133,54.527 42.441,54.527 L1.641,54.527 C0.949,54.527 0.391,53.965 0.391,53.273 L0.391,12.484 C0.391,11.789 0.949,11.23 1.641,11.23 L26.07,11.23" stroke="#D5D5D5" stroke-width="2.6025" stroke-linecap="round"></path>
            </g>
          </g>
        </svg>
        <h4 class="text-center">{{ settings.brand.brandName }} Presenter Checklist</h4>
        <p class="setup-test--sub-header">Go through this checklist before going live to make sure your system is setup properly and you're ready for showtime.</p>
      </div>
    </div>

    <div class="tile tile-setup" ng-if="!ctrl.subscriberOnly">
      <div class="row">
        <div class="col-sm-6">
          <h3><span>1</span> Test your Camera &amp; Mic</h3>
          <p class="m-b-1">Let's connect to your camera and mic and save those settings for later.</p>
        </div>
        <div class="col-sm-6">
          <div class="m-t-1 setup-page--list-action setup-page--list-action-buttons">
            <button type="button" *ngIf="!testingCamera" (click)="testCamera()" class="btn btn-success">Test Camera &amp; Mic</button>
            <button type="button" *ngIf="testingCamera" (click)="doneCamera()" class="btn btn-outline-danger">Done Testing</button>
          </div>
        </div>
      </div>
      <div *ngIf="testingCamera" class="row">
        <bxl-camera-test-modal [isModal]="false"></bxl-camera-test-modal>
      </div>
    </div>
    <div class="tile tile-setup">
      <div class="row">
        <div class="col-sm-6">
          <h3><span>2</span> Test Screen Share</h3>
          <p class="m-b-1">Let's make sure you can share your screen. Learn more about screen sharing in our support center article. If you're sharing a Keynote or Powerpoint there are some things to keep in mind:</p>
          <ul class="setup-page--list-sub-actions">
            <li *ngIf="isOsx" class="setup-page--list-sub-action">
              <a target="_blank" href="https://support.behaviorlive.com/hc/en-us/articles/360052159034-Screen-Sharing-On-Mac"> <i class="fas fa-desktop"></i> Sharing Permissions on Mac &gt;</a>
            </li>
            <li class="setup-page--list-sub-action">
              <a target="_blank" href="https://support.behaviorlive.com/hc/en-us/articles/360051757974-Sharing-a-Keynote-Presentation"> <img class="mr-2" width="20" src="https://s3.amazonaws.com/cc-public-prod/home/keynote-presentation.png" />Sharing a Keynote presentation &gt;</a>
            </li>
            <li class="setup-page--list-sub-action">
              <a target="_blank" href="https://support.behaviorlive.com/hc/en-us/articles/360051757294-Sharing-a-PowerPoint-Presentation"> <img class="mr-2" width="20" src="https://s3.amazonaws.com/cc-public-prod/home/powerpoint-presentation.png" />Sharing a Powerpoint presentation &gt;</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <div class="m-t-1 setup-page--list-action setup-page--list-action-buttons">
            <button type="button" *ngIf="!testingScreenShare" (click)="testScreenShare()" class="btn btn-success">Test Screen share</button>
            <button type="button" *ngIf="testingScreenShare" (click)="doneScreenShare()" class="btn btn-outline-danger">Done Testing</button>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <div class="camera-wrapper" #publisherDiv></div>
        </div>
      </div>
    </div>
    <div class="tile tile-setup">
      <div class="row">
        <div class="col-sm-6">
          <h3><span>3 </span> Share this page with your speakers</h3>
          <p class="m-b-1">{{ settings.brand.url }} allows anyone to take the stage and with their camera and mic. Be sure to share this setup page with anyone you plan to bring up on stage.</p>
        </div>
        <div class="col-sm-6 m-b-1">
          <div class="m-t-1">
            <div class="setup-page--list-action m-t-1">
              <input class="form-control input-lg inline-block" type="text" value="{{settings.brand.url}}/setup" />
              <div class="text-muted text-center m-t-half">Share this page's URL with speakers</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
