<!-- ========== MAIN CONTENT ========== -->
<main (resized)="onResized($any($event))" *ngIf="initialized" id="content" role="main">
  <!-- Hero Section -->
  <div id="SVGHero" class="position-relative bg-white overflow-hidden" style="">
    <div class="container position-relative z-index-2 space-top-2 space-bottom-3 space-top-md-2">
      <div class="row align-items-lg-center">
        <div class="col-lg-5 mb-11 mb-lg-0">
          <!-- Info -->
          <div class="mb-6">
            <h1 class="text-white font-weight-semi-bold mb-3">
              Event Mangement in a box
              <span class="text-indigo">
                <strong class="u-text-animation u-text-animation--typing"></strong>
              </span>
              <br />
<!--              {{ 'ceu-events-and-courses' | translate }}-->
            </h1>
            <p class="text-white-70">More than a software platform, GatherGo is your complete solution for Conferences, Events, Webinars, AV Production, and CEU content sales!</p>
          </div>

          <!-- <a class="btn btn-white btn-wide btn-pill text-primary shadow-soft transition-3d-hover mb-2 mb-sm-0 mr-4" [routerLink]="['/products-services']">Products & Services</a> -->
          <a class="btn btn-primary btn-wide btn-pill transition-3d-hover mb-2 mb-sm-0" [routerLink]="['products-services']">Learn More</a>
          <!-- End Info -->
        </div>

        <div class="col-lg-7">
          <div class="position-relative">
            <!-- SVG Laptop Mockup -->
            <figure class="ie-devices-v4-laptop mb-3 mb-sm-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 999.1 576.5"
                style="enable-background: new 0 0 999.1 576.5"
                xml:space="preserve"
                class="injected-svg js-svg-injector"
                data-img-paths='[
                     {"targetId": "#SVGlaptopImg1", "newPath": "../../assets/img/1618x1010/img3.jpg"}
                   ]'
                data-parent="#SVGHero"
              >
                <style type="text/css">
                  .laptop-0 {
                    fill: #ffffff;
                  }
                  .laptop-1 {
                    fill: #f7f7f7;
                  }
                  .laptop-2 {
                    fill: #efefef;
                  }
                  .laptop-3 {
                    fill: url(#laptopID1);
                  }
                </style>
                <path class="laptop-0 fill-white" d="M881.3,576.5H117.5c-14.4,0-26.2-11.8-26.2-26.2V26.2C91.3,11.8,103.1,0,117.5,0h763.9  c14.4,0,26.2,11.8,26.2,26.2v524.2C907.5,564.7,895.7,576.5,881.3,576.5z"></path>
                <circle class="laptop-1" cx="498" cy="23.3" r="3"></circle>
                <path class="laptop-0" d="M996.3,560.7H2.9c-1.5,0-2.8-1.3-2.8-2.8l0,0c0-1.5,1.3-2.8,2.8-2.8h993.4c1.5,0,2.8,1.3,2.8,2.8l0,0  C999.1,559.4,997.9,560.7,996.3,560.7z"></path>
                <path class="laptop-2" d="M499.6,562.5L0,560.7c0,0,24.1,11.3,117.8,15.8H498h3.1h380.2c93.7-4.5,117.8-15.8,117.8-15.8L499.6,562.5z"></path>
                <linearGradient id="laptopID1" gradientUnits="userSpaceOnUse" x1="403.8206" y1="558.6469" x2="594.97" y2="558.6469">
                  <stop offset="5.908129e-07" style="stop-color: #f0f1f2"></stop>
                  <stop offset="5.235744e-02" style="stop-color: #f6f7f8"></stop>
                  <stop offset="0.1708" style="stop-color: #fdfdfd"></stop>
                  <stop offset="0.5" style="stop-color: #ffffff"></stop>
                  <stop offset="0.8292" style="stop-color: #fdfdfd"></stop>
                  <stop offset="0.9476" style="stop-color: #f6f7f8"></stop>
                  <stop offset="1" style="stop-color: #f0f1f2"></stop>
                </linearGradient>
                <path class="laptop-3" d="M591.4,562.2H407.4c-1.9,0-3.5-1.6-3.5-3.5v-3.5H595v3.5C595,560.6,593.4,562.2,591.4,562.2z"></path>
                <g>
                  <defs>
                    <rect id="laptopID2" x="121.7" y="37.5" width="752.5" height="469.7"></rect>
                  </defs>
                  <clipPath id="laptopID3">
                    <use xlink:href="#laptopID2" style="overflow: visible"></use>
                  </clipPath>
                  <g style="clip-path: url(#laptopID3)">
                    <!-- Apply your (1618px width to 1010px height) image here -->
                    <image id="SVGlaptopImg1" style="overflow: visible" width="1618" height="1010" xlink:href="../../../assets/gather-go-home-screenshot.png" transform="matrix(0.4651 0 0 0.4651 121.7472 37.4679)"></image>
                  </g>
                  <use xlink:href="#laptopID2" style="overflow: visible; fill: none; stroke: #e7eaf3; stroke-miterlimit: 10"></use>
                </g>
              </svg>
            </figure>
            <!-- SVG Laptop Mockup -->

            <!-- Card Info -->
            <div class="position-sm-absolute bottom-0 right-0 max-width-40 ml-auto mb-sm-n5">
              <div class="card border-0 bg-primary shadow-primary-lg rounded p-5">
                <h2 class="h5 text-white font-weight-semi-bold mb-3">
                  Author Content &<br />
                  Get Paid
                </h2>
                <p class="text-white">Want some passive income? Sign up today, and start selling your content!</p>
                <p class="font-size-1 font-weight-medium" style="color: #051d40">Our world-class marketing team can help you host a live event!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SVG Background Shape -->
    <div class="gradient-overlay-half-home-hero svg-divider-polygon position-absolute top-0 right-0 bottom-0 left-0">
      <figure class="ie-circles-hero"></figure>
    </div>
    <!-- End SVG Background Shape -->
  </div>

  <!-- Get Started Section -->
  <div class="container space-2">
    <div class="row justify-content-md-between">
      <div class="col-sm-6 offset-sm-3 col-lg-4 offset-lg-0 mb-9 mb-lg-0">
        <!-- Icon Blocks -->
        <div class="text-center">
          <!-- SVG Icon -->
          <figure id="SVGcreateAccount" class="w-65 mx-auto mb-4">
            <img class="js-svg-injector" src="../../assets/svg/illustrations/create-account.svg" alt="Image Description"
                 data-parent="#SVGcreateAccount">
          </figure>
          <!-- End SVG Icon -->

          <div class="mb-4">
            <h1 class="h5">Header 1 here</h1>
            <p>Create a Front account. Submit applications, save listings and manage jobs.</p>
          </div>
          <a class="btn btn-sm btn-soft-admin-primary btn-wide transition-3d-hover" href="#">
            Create an Account
            <span class="fas fa-angle-right ml-2"></span>
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>

      <div class="col-sm-6 col-lg-4 mb-9 mb-sm-0">
        <!-- Icon Blocks -->
        <div class="text-center">
          <!-- SVG Icon -->
          <figure id="SVGpostResume" class="w-65 mx-auto mb-4">
            <img class="js-svg-injector" src="../../assets/svg/illustrations/post-resume.svg" alt="Image Description"
                 data-parent="#SVGpostResume">
          </figure>
          <!-- End SVG Icon -->

          <div class="mb-4">
            <h2 class="h5">Post your resume</h2>
            <p>Create an account and upload your resume to be found by the recruiters who search Monster's databases every day.</p>
          </div>
          <a class="btn btn-sm btn-soft-admin-primary btn-wide transition-3d-hover" href="#">
            Post your Resume
            <span class="fas fa-angle-right ml-2"></span>
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>

      <div class="col-sm-6 col-lg-4">
        <!-- Icon Blocks -->
        <div class="text-center">
          <!-- SVG Icon -->
          <figure id="SVGportfolio" class=" w-65 mx-auto mb-4">
            <img class="js-svg-injector" src="../../assets/svg/illustrations/portfolio.svg" alt="Image Description"
                 data-parent="#SVGportfolio">
          </figure>
          <!-- End SVG Icon -->

          <div class="mb-4">
            <h3 class="h5">Employers: Post a job</h3>
            <p>Your next hire is here. Our career advice, resume services and education partners give you a leg up in the job search.</p>
          </div>
          <a class="btn btn-sm btn-soft-admin-primary btn-wide transition-3d-hover" href="#">
            Post a Job
            <span class="fas fa-angle-right ml-2"></span>
          </a>
        </div>
        <!-- End Icon Blocks -->
      </div>
    </div>
  </div>
  <!-- End Get Started Section -->

  <!-- Features Section -->

  <!-- End Features Section -->

  <!-- Features Section -->
  <div class="container space-2 space-top-lg-3">
    <div class="row justify-content-lg-between align-items-lg-center">
      <div class="col-lg-5 mb-9 mb-lg-0">
        <div class="mb-5">
          <h2 class="font-weight-medium mb-4">Payment made easy in stores with speed and security using.</h2>
          <p>When paying in stores, Front Pay doesn't share your actual card number, so your information stays secure. Plus, you can check out faster and easier.</p>
        </div>

        <div class="w-md-50 w-lg-80 mb-2">
          <!-- Fancybox -->
          <div class="bg-img-hero text-center rounded-lg py-11 px-5" style="background-image: url(../../assets/img/480x320/img30.jpg);">
            <a class="js-fancybox d-inline-block u-media-player" href="javascript:;"
               data-src="//vimeo.com/167434033"
               data-speed="700"
               data-animate-in="zoomIn"
               data-animate-out="zoomOut"
               data-caption="Front - Responsive Website Template">
            <span class="u-media-player__icon">
              <span class="fas fa-play u-media-player__icon-inner"></span>
            </span>
            </a>
          </div>
          <!-- End Fancybox -->
        </div>

        <p class="small">See how to send money with Front Pay.</p>
      </div>

      <div class="col-lg-6">
        <div id="featuresSVG" class=" position-relative max-width-50 mx-auto">
          <!-- SVG Phone Mockup -->
          <div class="w-75 u-devices-v2__phone-svg shadow-sm mx-auto">
            <figure class="ie-iphone-x-1">
              <img class="js-svg-injector" src="../../assets/svg/components/iphone-x-1.svg" alt="Image Description"
                   data-img-paths='[
                   {"targetId": "#SVGiphoneX1Img1", "newPath": "../../assets/img/407x867/img1.jpg"}
                 ]'
                   data-parent="#featuresSVG">
            </figure>
          </div>
          <!-- End SVG Phone Mockup -->

          <!-- SVG Component -->
          <div class="position-absolute bottom-0 right-0 max-width-50 w-100 z-index-n1 mx-auto">
            <figure class="ie-abstract-shapes-12">
              <img class="js-svg-injector" src="../../assets/svg/components/abstract-shapes-12.svg" alt="Image Description"
                   data-parent="#featuresSVG">
            </figure>
          </div>
          <!-- End SVG Component -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Features Section -->

  <!-- Features Section -->
  <div class="container space-2 space-top-lg-3">
    <div class="row justify-content-lg-between align-items-lg-center">
      <div class="col-lg-5 order-lg-2 mb-9 mb-lg-0">
        <div class="mb-5">
          <h2 class="font-weight-medium mb-4">The easiest way to pay within apps.</h2>
          <p>Use Front Pay to make purchases in all kinds of apps. Pay for a ride, a pizza delivery, or a new pair of sneakers — with just a touch. Checking out is fast, simple, and secure.</p>
        </div>

        <h4 class="h6 mb-3">Get our mobile app</h4>
        <a class="btn btn-icon btn-indigo rounded-circle mr-2" href="#">
          <span class="fab fa-apple btn-icon__inner"></span>
        </a>
        <a class="btn btn-icon btn-indigo rounded-circle" href="#">
          <span class="fab fa-google-play btn-icon__inner"></span>
        </a>
      </div>

      <div class="col-lg-6 order-lg-1">
        <div id="featuresSVGExample2" class=" position-relative max-width-50 mx-auto">
          <!-- SVG Phone Mockup -->
          <div class="w-75 u-devices-v2__phone-svg shadow-sm mx-auto">
            <figure class="ie-iphone-x-1">
              <img class="js-svg-injector" src="../../assets/svg/components/iphone-x-2.svg" alt="Image Description"
                   data-img-paths='[
                   {"targetId": "#SVGiphoneX2Img1", "newPath": "../../assets/img/407x867/img2.jpg"}
                 ]'
                   data-parent="#featuresSVGExample2">
            </figure>
          </div>
          <!-- End SVG Phone Mockup -->

          <!-- SVG Component -->
          <div class="position-absolute bottom-0 left-0 right-0 max-width-50 w-100 z-index-n1 mx-auto mb-n5">
            <figure class="ie-abstract-shapes-12">
              <img class="js-svg-injector" src="../../assets/svg/components/abstract-shapes-13.svg" alt="Image Description"
                   data-parent="#featuresSVGExample2">
            </figure>
          </div>
          <!-- End SVG Component -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Features Section -->

  <!-- Features Section -->
  <div class="container space-2 space-top-lg-3">
    <div class="row justify-content-lg-between align-items-lg-center">
      <div class="col-lg-5 mb-9 mb-lg-0">
        <div class="mb-4">
          <h2 class="font-weight-medium mb-4">Get paid and use the cash. All with Front Pay.</h2>
          <p>When you receive money it's added to your Front Pay Cash card that lives in the Wallet app.</p>
        </div>

        <ul class="list-unstyled">
          <li class="media">
          <span class="btn btn-icon btn-xs btn-indigo rounded-circle mt-1 mr-3">
            <span class="fas fa-check btn-icon__inner"></span>
          </span>
            <div class="media-body">
              <p>When you pay with a debit or credit card, Front Pay doesn't keep transaction information that can be tied back to you.</p>
            </div>
          </li>
          <li class="media">
          <span class="btn btn-icon btn-xs btn-indigo rounded-circle mt-1 mr-3">
            <span class="fas fa-check btn-icon__inner"></span>
          </span>
            <div class="media-body">
              <p>When you use Front Pay Cash, information is stored only for troubleshooting.</p>
            </div>
          </li>
          <li class="media">
          <span class="btn btn-icon btn-xs btn-indigo rounded-circle mt-1 mr-3">
            <span class="fas fa-check btn-icon__inner"></span>
          </span>
            <div class="media-body">
              <p>You can also add your student ID card to Front Wallet to access places like your dorm and the library.</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-lg-6">
        <div id="featuresSVG3" class=" position-relative max-width-50 mx-auto">
          <!-- SVG Phone Mockup -->
          <div class="w-75 u-devices-v2__phone-svg shadow-sm mx-auto">
            <figure class="ie-iphone-x-1">
              <img class="js-svg-injector" src="../../assets/svg/components/iphone-x-3.svg" alt="Image Description"
                   data-img-paths='[
                   {"targetId": "#SVGiphoneX3Img1", "newPath": "../../assets/img/407x867/img3.jpg"}
                 ]'
                   data-parent="#featuresSVG3">
            </figure>
          </div>
          <!-- End SVG Phone Mockup -->

          <!-- SVG Component -->
          <div class="content-centered-y left-0 right-0 max-width-50 w-100 z-index-n1 mx-auto">
            <figure class="ie-abstract-shapes-12">
              <img class="js-svg-injector" src="../../assets/svg/components/abstract-shapes-14.svg" alt="Image Description"
                   data-parent="#featuresSVG3">
            </figure>
          </div>
          <!-- End SVG Component -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Features Section -->

  <div id="SVGelements" class="">
    <!-- SVG Background -->
    <figure class="position-absolute right-0 bottom-0 left-0">
      <img class="js-svg-injector" src="../../assets/svg/components/wave-1-bottom-sm.svg" alt="Image Description"
           data-parent="#SVGelements">
    </figure>
    <!-- End SVG Background -->

    <div class="gradient-half-primary-v3">
      <div class="container space-top-2 space-top-md-3 space-bottom-1">
        <div class="row justify-content-lg-between align-items-lg-start">
          <div class="col-lg-5 mb-7 mb-lg-0">
            <!-- Title -->
            <div class="pr-md-4 mb-4">
              <h2 class="text-primary">Let's get you <span class="font-weight-semi-bold">started</span></h2>
              <p>Start knowing what your attendees value, and win more business with Front template.</p>
            </div>
            <!-- End Title -->

            <a class="btn btn-primary btn-wide btn-pill transition-3d-hover mr-1 mb-2" href="https://themes.getbootstrap.com/product/front-multipurpose-responsive-template/" target="_blank">Buy Now</a>
            <a class="btn btn-soft-primary btn-wide btn-pill transition-3d-hover ml-1 mb-2" href="../pages/login-simple.html">Get Started</a>
          </div>

          <div class="col-lg-6 mt-auto">
            <!-- SVG Icon -->
            <figure class="ie-working-men">
              <img class="js-svg-injector" src="../../assets/svg/illustrations/app-development.svg" alt="SVG Illustration"
                   data-parent="#SVGelements">
            </figure>
            <!-- End SVG Icon -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- End Hero Section -->
<!--  <div class="container space-2">-->
<!--    &lt;!&ndash; Title &ndash;&gt;-->
<!--    <div class="d-sm-flex justify-content-sm-between align-items-sm-center mb-5">-->
<!--      <h2 class="h3 font-weight-medium">{{ 'upcoming-conferences' | translate }}</h2>-->
<!--      <a class="link-muted" [routerLink]="['/conferences']">-->
<!--        {{ 'view-all' | translate }}-->
<!--        <span class="fas fa-angle-right small ml-2"></span>-->
<!--      </a>-->
<!--    </div>-->
<!--    &lt;!&ndash; End Title &ndash;&gt;-->

<!--    <div class="row">-->
<!--      <div *ngIf="!conferences" class="col-md-12">-->
<!--        <p-skeleton width="100%" height="200px"></p-skeleton>-->
<!--      </div>-->
<!--      <div *ngIf="conferences" class="col-md-12">-->
<!--        <p-carousel [numVisible]="4" [numScroll]="1" [value]="conferences" [responsiveOptions]="responsiveOptions">-->
<!--          <ng-template let-conf pTemplate="item">-->
<!--            <bxl-conference-item [conference]="conf" [isGrid]="true"></bxl-conference-item>-->
<!--          </ng-template>-->
<!--        </p-carousel>-->
<!--        &lt;!&ndash; <carousel [height]="420" [cellWidth]="400">-->
<!--          <div *ngFor="let conf of conferences" class="carousel-cell">-->
<!--            <bxl-conference-item [conference]="conf" [isGrid]="true"></bxl-conference-item>-->
<!--          </div>-->
<!--        </carousel> &ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div id="SVGbgElements6" class="position-relative overflow-hidden">-->
<!--    <div class="container">-->
<!--      <div class="d-sm-flex justify-content-sm-between align-items-sm-center mb-5">-->
<!--        <h2 class="h3 font-weight-medium">{{ 'featured-on-demand-content' | translate }}</h2>-->
<!--        <a class="link-muted" [routerLink]="['/course-library']">-->
<!--          {{ 'search-library' | translate }}-->
<!--          <span class="fas fa-angle-right small ml-2"></span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div *ngIf="!featuredCourses" class="col-md-12">-->
<!--          <p-skeleton width="100%" height="200px"></p-skeleton>-->
<!--        </div>-->
<!--        <div *ngIf="featuredCourses" class="col-md-12">-->
<!--          <p-carousel [numVisible]="5" [value]="featuredCourses" [responsiveOptions]="responsiveOptions2">-->
<!--            <ng-template let-course pTemplate="item">-->
<!--              <div [routerLink]="['/courses', course.slug]" class="card cursor-pointer">-->
<!--                <bxl-course-item [course]="course" [isGrid]="true"></bxl-course-item>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </p-carousel>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <figure class="position-absolute top-0 right-0 z-index-n1 w-100 h-100">-->
<!--      <img class="js-svg-injector" src="../../assets/svg/components/bg-elements-6.svg" alt="SVG" data-parent="#SVGbgElements6" />-->
<!--    </figure>-->
<!--  -->
<!--  </div>-->
</main>
<!-- ========== END MAIN CONTENT ========== -->
<!-- ========== MAIN CONTENT ========== -->

<!-- ========== END MAIN CONTENT ========== -->
