import { Component, OnInit, Input } from '@angular/core';
import { CourseEventViewModel, EventViewModel } from 'src/app/_models/generatedModels';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import 'moment-timezone';
import { UserService } from 'src/app/_services/generatedServices';
import { NavigationService } from "../../_services/navigation-service";
import { Router } from "@angular/router";

@Component({
  selector: 'bxl-event-item',
  templateUrl: 'event-item.component.html',
})
export class EventItemComponent implements OnInit {
  @Input() event: EventViewModel;
  @Input() rootRoute: string = "/";
  initialized = false;
  timezone: string;
  isFavorited: boolean = false;

  constructor(public auth: AuthenticationService, private userService: UserService, private  router:Router, private navService:NavigationService) {}

  ngOnInit(): void {
    let timeZone = moment.tz.guess();
    let timeZoneOffset = new Date(this.event.startTime).getTimezoneOffset();
    this.timezone = moment.tz(timeZone).format('z');

    if (this.event.userHasPurchased && this.event.conferenceId) {
      this.userService.isUserCourseEventFavorited(this.event.id).subscribe((isFavorited) => {
        this.isFavorited = isFavorited;
        this.initialized = true;
      });
    } else {
      this.initialized = true;
    }
  }

  onFavoriteChange() {
    if (this.isFavorited) {
      this.userService.unFavoriteCourseEvent(this.event.id).subscribe(() => {
        this.isFavorited = false;
      });
    } else {
      this.userService.favoriteCourseEvent(this.event.id).subscribe(() => {
        this.isFavorited = true;
      });
    }
  }

  public subscribe() {
    if (this.auth.isLoggedIn()){
      this.router.navigate(['/account/subscription']);
    }else{
      this.navService.showLogin().then(()=> this.router.navigate(['/account/subscription']));
    }
  }
}
