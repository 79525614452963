<div class="modal-content">
  <div class="text-center p-2 signup-header">
    <button type="button" class="close float-right" data-dismiss="modal" aria-hidden="true" (click)="close()">X</button>
    <h2 *ngIf="stage < 4" class="text-center">Login/Sign-up</h2>

    <h2 *ngIf="stage == 4" class="text-center">{{ 'event-registration' | translate }}</h2>
    <h2 *ngIf="stage == 5" class="text-center">{{ 'purchase-content' | translate }}</h2>
    <h2 class="text-center" *ngIf="stage == 6">{{ 'password-reset' | translate }}</h2>
    <h4 class="text-center" *ngIf="stage == 6">We sent an email to {{ emailAddress }} with instructions on resetting your password.</h4>
  </div>
  <div class="modal-body p-1">
    <form #theForm="ngForm">
      <h5 class="text-center" *ngIf="stage == 2">Welcome back {{ firstName }}!</h5>
      <h5 class="text-center" *ngIf="stage == 3">{{ 'lets-create-an-account' | translate }}</h5>
      <div *ngIf="errorMessage" class="alert alert-primary" role="alert">
        {{ errorMessage }}
      </div>
      <div>
        <div class="form-group" *ngIf="stage == 1 || stage == 2 || stage == 3">
          <label for="uname1">{{ 'email' | translate }}</label>
          <div class="input-group mb-3">
            <input type="text" [(ngModel)]="emailAddress" (keydown.space)="$event.preventDefault()" [readonly]="stage == 2 || stage == 3" name="emailAddress" class="form-control" placeholder="Email" aria-label="emailAddress" aria-describedby="basic-addon1" [email]="true" required />
            <div *ngIf="stage == 2 || stage == 3" class="input-group-append">
              <span class="input-group-text" id="basic-addon1"><a [routerLink]="[]" (click)="resetForm()">Change</a></span>
            </div>
            <div class="invalid-message">
              <div>Invalid Email address</div>
            </div>
          </div>
        </div>
        <div *ngIf="stage == 2 || stage == 3" class="form-group">
          <label for="uname1">{{ 'password' | translate }}</label>
          <div class="input-group">
            <input #x type="password" [(ngModel)]="password" name="password" required class="form-control" />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon1"
                ><a [routerLink]="[]" (click)="x.type = x.type == 'password' ? 'text' : 'password'"><i class="fa fa-eye-slash" aria-hidden="true"></i></a
              ></span>
            </div>
            <div class="invalid-message">
              <div>{{ 'password-is-required' | translate }}</div>
            </div>
          </div>
          <p *ngIf="stage != 3">
            <a (click)="forgotPassword()" [routerLink]="" class="cursor-pointer">{{ 'forgot-password' | translate }}</a>
          </p>
        </div>
        <div *ngIf="stage == 3" class="form-group">
          <label for="uname1">{{ 'first-name' | translate }}</label>
          <input type="text" [(ngModel)]="firstName" name="firstName" required class="form-control" />
          <div class="invalid-message">
            <div>{{ 'first-name-is-required' | translate }}</div>
          </div>
        </div>
        <div *ngIf="stage == 3" class="form-group">
          <label for="uname1">{{ 'last-name' | translate }}</label>
          <input type="text" [(ngModel)]="lastName" name="lastName" required class="form-control" />
          <div class="invalid-message">
            <div>{{ 'last-name-is-required' | translate }}</div>
          </div>
        </div>
        <div *ngIf="stage == 3" class="form-group">
         <small> By clicking "sign up" below I give {{ settings.brand.brandName }} permission to send email communications about upcoming events, conferences and other offerings.</small>
        </div>
        <div class="form-group text-center">
          <button *ngIf="stage == 1" type="button" class="btn btn-primary" [promiseBtn]="submitComplete" data-dismiss="modal" aria-hidden="true" (click)="emailEntered()">Continue with email</button>
          <button *ngIf="stage == 3" type="button" class="btn btn-primary" [promiseBtn]="submitComplete" data-dismiss="modal" aria-hidden="true" (click)="signUp()">Sign Up</button>
          <button *ngIf="stage == 2" type="button" class="btn btn-primary" [promiseBtn]="submitComplete" data-dismiss="modal" aria-hidden="true" (click)="login()">Log In</button>
        </div>
      </div>
      <div *ngIf="stage == 4 && !event.userHasPurchased">
        <h6 class="text-center register-event-title">{{ event.title }}</h6>

        <h6 class="text-center">{{ event.instructor.fullName }}</h6>
        <h3 *ngIf="event.price > 0" class="text-center">{{ actualPriceEvent | currency: event.currencyCode:'symbol' }}</h3>
        <h3 *ngIf="event.price === 0" class="text-center">{{ 'free' | translate }}</h3>
        <div *ngIf="event.memberPricing" class="col-md-12 mb-4">
          <label class="d-block" for="uname1"> If you are a member of {{event.organizationSlug}}, you are eligible for a discount. Click Below to look up your membership and apply the discount.</label>
          <button *ngIf="!membershipLookupResult" class="btn btn-admin-primary" type="button" (click)="checkMembership()">Lookup Membership</button>
          <div *ngIf="membershipLookupResult" class="">
            <div class="input-group mb-3">
              <input class="form-control" readonly value="Linked Member: {{ membershipLookupResult.firstName }} {{ membershipLookupResult.lastName }}" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="membershipLookupResult = null; isMember = false; calculatePrice()" type="button" id="button-addon2">Unlink</button>
              </div>
              <div *ngIf="promoError" class="invalid-message-visible">
                <div>{{ promoError }}</div>
              </div>
            </div>
            <!-- <h5 class="">Linked Member: {{membershipLookupResult.firstName}} {{membershipLookupResult.lastName}} <a href="javascript:void()" (click)="">(Unlink)</a></h5> -->
          </div>
          <div class="invalid-message">
            <div>Please indicate your membership status</div>
          </div>
        </div>
        <div class="form-row pl-2 pr-2" *ngIf="event.price > 0">
          <div class="col-md-6">
            <label for="uname1">{{ 'promo-code' | translate }}</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Promo Code" name="promoCode" [(ngModel)]="promoCode" aria-label="Promo Code" aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="applyPromoCodeEvent()" type="button" id="button-addon2">{{ 'apply' | translate }}</button>
              </div>
              <div *ngIf="promoError" class="invalid-message-visible">
                <div>{{ promoError }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row pl-2 pr-2" *ngIf="event.optInLanguage">
          <div class="col-md-12">
            <label for="uname1" [innerHTML]="event.optInLanguage">{{ event.optInLanguage }}</label>
            <select class="form-control" required [(ngModel)]="optIn" name="optIn">
              <option [ngValue]="false">No thanks. I'm just here for the CEUs</option>
              <option *ngIf="event.restrictToOrganizationId == 34" [ngValue]="true">Yes. I love their mission of changing lives! Please put me in touch with someone who can tell me more about the organization.</option>
              <option *ngIf="event.restrictToOrganizationId != 34" [ngValue]="true">Yes. I would like to learn more information.</option>
            </select>
            <div class="invalid-message">
              <div>This field is required</div>
            </div>
          </div>
        </div>
        <div class="form-row pl-2 pr-2" *ngIf="actualPriceEvent > 0">
          <div class="col-md-6">
            <label for="uname1">Card Number</label>
            <input type="text" class="form-control" [(ngModel)]="cardNumber" name="cardNumber" required creditcard placeholder="Card Number" />
            <div class="invalid-message">
              <div>Card Number is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">Month</label>
            <select class="form-control" [(ngModel)]="expMo" name="expMo" required>
              <option value="1">01 - January</option>
              <option value="2">02 - February</option>
              <option value="3">03 - March</option>
              <option value="4">04 - April</option>
              <option value="5">05 - May</option>
              <option value="6">06 - June</option>
              <option value="7">07 - July</option>
              <option value="8">08 - August</option>
              <option value="9">09 - September</option>
              <option value="10">10 - October</option>
              <option value="11">11 - November</option>
              <option value="12">12 - December</option>
            </select>
            <div class="invalid-message">
              <div>Month is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">Year</label>
            <select class="form-control" [(ngModel)]="expYr" name="expYr" required>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
            </select>
            <div class="invalid-message">
              <div>Year is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">CVV</label>
            <input type="text" class="form-control" [(ngModel)]="cvv" name="cvv" placeholder="CVV" required />
            <div class="invalid-message">
              <div>CVV is required</div>
            </div>
          </div>
        </div>
        <div *ngIf="!event.captureLinkedInProfile" class="form-group text-center mt-3">
          <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" aria-hidden="true" [promiseBtn]="submitComplete" (click)="completeRegistration()">{{ 'complete-registration' | translate }}</button>
        </div>
        <div *ngIf="event.captureLinkedInProfile" class="form-group text-center mt-3">
          <!-- <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" aria-hidden="true" [promiseBtn]="submitComplete" (click)="linkedInRegistration()">Register with LinkedIn</button> -->
          <a href="{{ linkedInUrl }}" class="btn btn-instagram btn-block"> <i class="fab fa-linkedin"></i> Register with LinkedIn</a>
          <a class="small text-black" href="javascript:void()" [promiseBtn]="submitComplete" (click)="completeRegistration()">Register here if you don't have a LinkedIn Account</a>
        </div>
      </div>
      <div class="text-center" *ngIf="stage == 4 && event.userHasPurchased">
        <h4>You have already registered for this event</h4>
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" aria-hidden="true" (click)="close()">Close</button>
      </div>
      <div *ngIf="stage == 5 && !course.userHasPurchased">
        <h6 class="text-center register-event-title">{{ course.title }}</h6>
        <h6 class="text-center">{{ course.instructor.fullName }}</h6>
        <h3 *ngIf="course.price > 0" class="text-center">{{ actualPrice | currency: course.currencyCode:'symbol' }}</h3>
        <h3 *ngIf="course.price === 0" class="text-center">FREE</h3>
        <div *ngIf="course.memberPricing" class="col-md-12 mb-4">
          <label class="d-block" for="uname1"> If you are a member of {{course.organizationShortName}}, you are eligible for a discount. Click Below to look up your membership and apply the discount.</label>
          <button *ngIf="!membershipLookupResult" class="btn btn-admin-primary" type="button" (click)="checkMembership()">Lookup Membership</button>
          <div *ngIf="membershipLookupResult" class="">
            <div class="input-group mb-3">
              <input class="form-control" readonly value="Linked Member: {{ membershipLookupResult.firstName }} {{ membershipLookupResult.lastName }}" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="membershipLookupResult = null; isMember = false; calculatePrice()" type="button" id="button-addon2">Unlink</button>
              </div>
              <div *ngIf="promoError" class="invalid-message-visible">
                <div>{{ promoError }}</div>
              </div>
            </div>
            <!-- <h5 class="">Linked Member: {{membershipLookupResult.firstName}} {{membershipLookupResult.lastName}} <a href="javascript:void()" (click)="">(Unlink)</a></h5> -->
          </div>
          <div class="invalid-message">
            <div>Please indicate your membership status</div>
          </div>
        </div>
        <div class="form-row" *ngIf="course.price > 0">
          <div class="col-md-6">
            <label for="uname1">Promo Code</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Promo Code" name="promoCode" [(ngModel)]="promoCode" aria-label="Promo Code" aria-describedby="button-addon2" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="applyPromoCodeCourse()" type="button" id="button-addon2">Apply</button>
              </div>
              <div *ngIf="promoError" class="invalid-message-visible">
                <div>{{ promoError }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="actualPrice > 0">
          <div class="col-md-6">
            <label for="uname1">Card Number</label>
            <input type="text" class="form-control" [(ngModel)]="cardNumber" name="cardNumber" required creditcard placeholder="Card Number" />
            <div class="invalid-message">
              <div>Card Number is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">Month</label>
            <select class="form-control" [(ngModel)]="expMo" name="expMo" required>
              <option value="1">01 - January</option>
              <option value="2">02 - February</option>
              <option value="3">03 - March</option>
              <option value="4">04 - April</option>
              <option value="5">05 - May</option>
              <option value="6">06 - June</option>
              <option value="7">07 - July</option>
              <option value="8">08 - August</option>
              <option value="9">09 - September</option>
              <option value="10">10 - October</option>
              <option value="11">11 - November</option>
              <option value="12">12 - December</option>
            </select>
            <div class="invalid-message">
              <div>Month is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">Year</label>
            <select class="form-control" [(ngModel)]="expYr" name="expYr" required>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
            </select>
            <div class="invalid-message">
              <div>Year is required</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-4">
            <label for="uname1">CVV</label>
            <input type="text" class="form-control" [(ngModel)]="cvv" name="cvv" placeholder="CVV" required />
            <div class="invalid-message">
              <div>CVV is required</div>
            </div>
          </div>
        </div>
        <div class="form-group text-center mt-3">
          <button *ngIf="course.price > 0" type="button" class="btn btn-primary btn-block" data-dismiss="modal" aria-hidden="true" [promiseBtn]="submitComplete" (click)="purchaseCourse()">Complete Purchase</button>
          <button *ngIf="course.price == 0" type="button" class="btn btn-success btn-block" data-dismiss="modal" aria-hidden="true" [promiseBtn]="submitComplete" (click)="purchaseCourse()">Add to Library</button>
        </div>
      </div>
      <div class="text-center" *ngIf="stage == 5 && course.userHasPurchased">
        <h4>You have already purchased this course</h4>
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" aria-hidden="true" (click)="close()">Close</button>
      </div>
    </form>
    <div class="form-group text-center mt-3">
      <button [promiseBtn]="submitComplete" *ngIf="stage == 6" type="button" class="btn btn-primary btn-lg" data-dismiss="modal" aria-hidden="true" (click)="activeModal.dismiss()">Done</button>
    </div>
  </div>
</div>
