<app-nav-menu [isCompanyPage]="false"></app-nav-menu>

<router-outlet></router-outlet>

<!-- ========== FOOTER ========== -->
<footer class="container text-center space-2">
  <!-- Logo -->
  <!-- End Logo -->

  <!-- List -->
  <ul class="list-inline list-group-flush list-group-borderless">
    <li class="list-inline-item px-2">
      <a class="list-group-item-action" [routerLink]="['/terms']">{{ 'terms-of-service' | translate }}</a>
    </li>
    <li class="list-inline-item px-2">
      <a class="list-group-item-action" [routerLink]="['/privacy']">{{ 'privacy-policy' | translate }}</a>
    </li>
  </ul>
  <!-- End List -->

  <!-- Copyright -->
  <p class="small text-muted mb-0">© {{year}} {{settings.brand.brandName}}</p>
  <p class="small text-muted mb-0">{{settings.configuration.version}}</p>
  <p class="small text-muted mb-0">{{settings.configuration.clientIP}}</p>
  <!-- End Copyright -->
</footer>
<!-- ========== END FOOTER ========== -->
<!-- ========== END FOOTER ========== -->
