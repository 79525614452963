import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsLoggerService } from './app-Insights-logger.service';

@Injectable()
export class AppInsightsErrorHandlerService extends ErrorHandler {
  constructor(private loggerService: AppInsightsLoggerService) {
    super();
  }

  handleError(error: Error) {

    this.loggerService.logError(error);
    console.error(error);
  }
}
