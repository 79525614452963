<page-load-header *ngIf="!initialized"></page-load-header>
<ng-container *ngIf="initialized">
  <div *ngIf="notAuthorized" class="container space-2 text-center">
    <div class="alert alert-danger">
      <h4>You are not authorized to view this event.</h4>
      <h5>If this is an internal event for your organization, make sure you are logged in with your organization email account.</h5>
    </div>
  </div>
  <ng-container *ngIf="!notAuthorized">
    <main class="event-details-page" [ngClass]="{ 'conference-event': isConference }">
      <div class="container space-1">
        <button *ngIf="!isConference" [routerLink]="['..']" type="button" class="btn event-text btn-xs p-0" style="margin-top: -35px">
          <i class="fas fa-angle-left"></i>
          <strong> {{ 'upcoming-events' | translate }}</strong>
        </button>
        <button *ngIf="isConference" (click)="goBack()" type="button" class="btn event-text btn-xs p-0" style="margin-top: -35px">
          <i class="fas fa-angle-left"></i>
          <strong> {{ 'upcoming-events' | translate }}</strong>
        </button>
        <div class="row">
          <div class="col-md-6 event-text">
            <h3 *ngIf="event.userHasPurchased" class="event-text">{{ 'you-are-registered' | translate }}</h3>

            <ng-container *ngIf="event.userHasPurchased && !event.inPersonOnly">
              <div class="mb-7">
                <div class="no-candidates p-2">
                  <p class="event-text" *ngIf="!event.allowJoin">{{ 'not-time-to-join-full' | translate }}</p>
                  <h6 *ngIf="event.allowJoin">{{ 'its-time-to-join' | translate }}</h6>
                  <button *ngIf="!event.allowJoin" [disabled]="true" [routerLink]="['']" routerLinkActive="router-link-active" class="btn btn-warning">{{ 'not-yet-time-to-join' | translate }}</button>
                  <button *ngIf="event.allowJoin && !event.isExternal" [disabled]="!event.allowJoin" [routerLink]="['/live-events', event.id, 'live']" routerLinkActive="router-link-active" type="button" class="btn btn-success">{{ 'join-now' | translate }}</button>
                  <a *ngIf="event.allowJoin && event.isExternal" href="{{ event.isExternalLink }}" type="button" class="btn btn-success">{{ 'join-now' | translate }}</a>
                  <div *ngIf="event.showQuestions || event.showRequests" class="input-group mt-1">
                    <input *ngIf="event.showQuestions" type="text" class="form-control" [(ngModel)]="question" (keyup.enter)="sendQuestion()" placeholder="{{ 'ask-instructor-a-question' | translate }}" aria-label="" aria-describedby="button-addon2"/>
                    <input *ngIf="event.showRequests" type="text" class="form-control" [(ngModel)]="question" (keyup.enter)="sendQuestion()" placeholder="Request a song..." aria-label="" aria-describedby="button-addon2"/>
                    <div class="input-group-append">
                      <button class="btn btn-warning" type="button" (click)="sendQuestion()" id="button-addon2">
                        <span *ngIf="event.showQuestions">{{ 'ask-instructor' | translate }}</span
                        ><span *ngIf="event.showRequests">Request Song</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="event.inPersonOnly && event.userHasPurchased">
              <div class="no-candidates alert-warning p-2">
                <h5 class="">This event is only available to in-person attendees.</h5>
              </div>
            </ng-container>

            <h1 *ngIf="!event.userHasPurchased" class="event-text" [ngClass]="{ 'event-detail-title-long': event.title.length > 75, 'event-detail-title': event.title.length <= 75 }">{{ event.title }}</h1>
            <h5 *ngIf="event.userHasPurchased" class="event-text">{{ event.title }}</h5>

            <p *ngIf="event.organizationName && !event.conferenceName" class="mb-3">
              <strong class="event-text">{{ 'presented-by' | translate }}: </strong>
              <a style="color:#00bff3" [routerLink]="['/organizations', event.organizationSlug]">{{ event.organizationName }}</a>
            </p>
            <div class="row m-0">
              <div class="mr-2 mb-2">
                <a style="color:#00bff3" class="font-size-1" [routerLink]="['../../instructors', event.instructor.slug]">
                  <img class="img-fluid rounded-circle mr-1" style="width: 30px; height: 30px" style="max-width: 30px" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ event.instructor.id }}-avatar.png"/>
                  {{ event.instructor.firstName + ' ' + event.instructor.lastName }}
                  <span *ngIf="event.instructor.pronouns"> ({{ event.instructor.pronouns }})</span>
                </a>
              </div>
              <div *ngFor="let instructor of event.coInstructors" class="mr-2 mb-2">
                <a  style="color:#00bff3"*ngIf="!instructor.hidden" class="font-size-1" [routerLink]="['../../instructors', instructor.slug]">
                  <img class="img-fluid rounded-circle mr-1" style="width: 30px; height: 30px" style="max-width: 30px" src="https://bxlimages.blob.core.windows.net/avatar-cache/{{ instructor.id }}-avatar.png"/>
                  {{ instructor.firstName + ' ' + instructor.lastName }}
                  <span *ngIf="instructor.pronouns"> ({{ instructor.pronouns }})</span>
                </a>
              </div>
            </div>

            <ng-container *ngIf="!event.userHasPurchased">
              <div *ngIf="event.packageOnly" class="alert alert-warning">This event is offered only as part of a package. To register, please purchase a package that includes this event.
                <a *ngIf="event.conferenceSlug" [routerLink]="['/', event.conferenceSlug, 'registration']">View Packages</a>
                <a *ngIf="event.restrictToOrganizationId && !event.conferenceSlug" [routerLink]="['/organizations/', event.restrictToOrganizationId]">View Packages</a>
              </div>

              <ng-container *ngIf="!event.packageOnly">
                <div class="mt-3 mb-3">
                  <h5 *ngIf="event.includedInSubscription && !event.conferenceId && !auth.user?.hideSubscription && !event.userHasPurchased"  class="mt-1" style="font-weight: 500"><span class="fas fa-lightbulb-dollar text-success mr-2"></span> Included with a Subscription <a style="color:#e84d56" href="javascript:void(0)" *ngIf="!auth.user?.hasSubscription" (click)="subscribe()" >(Subscribe for $24.95)</a> </h5>

                  <button type="button" (click)="register()" class="btn btn-event-register btn-success transition-3d-hover">
                    <span class="mr-2"
                    >{{ 'register-now' | translate }}
                      <span *ngIf="event.price == 0">{{ 'free' | translate }}</span
                      ><span *ngIf="event.price > 0">{{ event.price | currency: event.currencyCode:'symbol' }}</span></span
                    >
                    |
                    <span class="ml-2">{{ event.ceus | ceuDisplay: true }} CEUs</span>
                  </button>
                </div>
                <div *ngIf="event.allowRecording && !event.inPersonOnly" class="alert alert-success">
                  <strong class="d-block">Register Now, watch later!</strong>
                  This event
                  <strong>will be</strong> recorded and available on-demand for existing registrants. Register now, and access it live or on-demand for CEUs.
                </div>
                <div *ngIf="!event.allowRecording && !event.inPersonOnly" class="alert alert-warning">
                  <strong class="d-block">Live Only</strong>
                  This event
                  <strong>WILL NOT</strong> be recorded and will only be offered live.
                </div>
                <div *ngIf="event.inPersonOnly" class="alert alert-warning">
                  <strong class="d-block">In-Person Only</strong>
                  This event is only available to in-person attendees.
                </div>

              </ng-container>
            </ng-container>
          </div>
          <div class="col-md-6">
            <div class="row event-text">
              <div class="col-md-12 mb-3 order-6 order-md-1">
                <img class="img-fluid event-image" src="https://bxlimages.blob.core.windows.net/images/{{ event.marketingSplashPath }}" [fallback]="'/fetchimage?fileName=c7164287-3135-4309-b3c8-4768092714e4Coming_soon.jpg'"/>
              </div>

              <div class="col-md-6 mb-2 order-2 order-md-2">
                <div class="event-detail-label">{{ 'date' | translate }}</div>
                <div class="d-block h5">{{ event.startTime | date: 'longDate' }}</div>
              </div>

              <div class="col-md-6 mb-2 order-3 order-md-2">
                <div class="event-detail-label">{{ 'time' | translate }}</div>
                <div class="d-block h5">{{ event.startTime | date: 'shortTime' }} - {{ event.endTime | date: 'shortTime' }} {{ timezone }}</div>
              </div>
              <div class="col-md-12 mb-2 order-4 order-md-2">
                <div class="event-detail-label">CEUs</div>
                <div class="d-block h5">{{ event.ceus | ceuDisplay }}</div>
              </div>
              <!-- <div class="col-md-6 mb-2 order-5 order-md-2">
                <div class="event-detail-label">{{ 'Categories' | translate }}</div>
                <div class="d-block h5">
                  <span *ngFor="let tag of event.tags" class="d-inline-block mr-1 mb-2"
                    ><a class="btn btn-xs text-primary mt-2" [ngClass]="{ 'btn-outline-primary': isConference, 'btn-white': !isConference }" [routerLink]="['/course-library', tag]">{{ tag }}</a></span
                  >
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="mt-3 ckembed">{{ 'event-description' | translate }}</h4>
          <div [editorHTML]="event.description"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h4 class="mt-3 ckembed">Learning Objectives</h4>
          <ul>
            <li *ngIf="event.learningObjective1">{{ event.learningObjective1 }}</li>
            <li *ngIf="event.learningObjective2">{{ event.learningObjective2 }}</li>
            <li *ngIf="event.learningObjective3">{{ event.learningObjective3 }}</li>
          </ul>
        </div>
      </div>

      <div *ngIf="event.userHasPurchased" class="row mb-5">
        <div class="col-md-12">
          <h4 class="mt-3 ckembed">Handouts</h4>
          <ul class="mb-5">
            <ng-container *ngFor="let material of materials">
              <li class="mb-0">
                <div class="d-flex align-items-center pb-1">
                  <div class="mr-auto">
                  <span class="participant-name">
                    <h6>
                      <a href="javascript:void(0)" (click)="downloadMaterial(material)">
                        <img style="width: 20px" src="../../assets/filetypes/{{ material.fileType }}.png"/>
                        {{ material.description }}</a
                      >
                    </h6></span
                  >
                  </div>

                </div>
              </li>
            </ng-container>
          </ul>
          <!--          <ul>-->
          <!--            <li *ngFor="let material of materials">{{material.fileName}}</li>-->
          <!--          </ul>-->
          <div class="mb-5"></div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
